import debug from 'debug'
import * as Sentry from '@sentry/vue'
import { location } from 'utils'
import pick from 'lodash/pick'

const log = debug('app:clients/sentry')

/* eslint-enable no-invalid-this */

const ignoredErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',

  // unhelpful or triggered constantly by third party integrations resulting in too much noise
  'Permission denied',
  'Error loading script',
  'UnknownError',
  'Unspecified error.',
  'No error message',
  'Access is denied.',
  "Cannot read properties of undefined (reading 'safeToUnload')",

  // non-breaking error on ios devices with iframe processing
  'Blocked a frame with origin "https://ilclassroom.com"',
  'target.postMessage is not a function.',
  "Unable to get property '1' of undefined or null reference",

  // triggered by Wistia
  "Cannot read property 'turnOn' of undefined",
  "Failed to execute 'removeChild' on 'Node': parameter 1 is not of type 'Node'.",

  // Learnosity
  '50001: DOM element missing',

  /^error$/,
  /^timeout$/,
]

const filteredErrors = [
  'Script error.', // triggered by 3rd party scripts without an anonymous crossorigin policy
  'ResizeObserver loop limit exceeded', // both triggered by BrainingCamp JS included by Learnosity
  'ResizeObserver loop completed with undelivered notifications',
  'this.questionsApiInstance.safeToUnload is not a function', // triggered by Learnosity
  'MathJax failed to load', // triggered by one class at guilfordcounty who can't load MathJax
]

const filteredScripts = ['itemengine']

const sentryConfig = {
  environment: '',
  domain: '',
  release: '',
  isMonitoring: false,
  initialized: false,
}

function isReady() {
  return sentryConfig.isMonitoring && sentryConfig.initialized
}
export function setConfig(env, domain, release) {
  sentryConfig.environment = env
  sentryConfig.domain = domain
  sentryConfig.release = release
  sentryConfig.isMonitoring = (env === 'production' || env === 'staging') && isOnline()
}

export function captureException(...args) {
  if (isReady()) {
    Sentry.captureException(...args)
    log('Sent to sentry:', ...args)
  } else {
    log('Not sent to sentry:', ...args)
  }
}

export function captureMessage(...args) {
  if (isReady()) {
    Sentry.captureMessage(...args)
    log('Sent to sentry:', ...args)
  } else {
    log('Not sent to sentry:', ...args)
  }
}

function isOnline() {
  return location.hostname !== ''
}

export function initializeSentry(apps) {
  if (sentryConfig.isMonitoring && !sentryConfig.initialized) {
    Sentry.init({
      dsn: 'https://2a85cb1ddff84e4b89c832df03a700cc@app.getsentry.com/75546',
      tags: { environment: sentryConfig.environment, domain: sentryConfig.domain },
      environment: sentryConfig.environment,
      ignoreErrors: ignoredErrors,
      release: sentryConfig.release,
      sampleRate: 0.2,
      app: apps,
      trackComponents: true,
      integrations: [
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          // FIXME: currently blowing up error quota, but disabling means we miss out on potentially useful info
          onunhandledrejection: false,
        }),
      ],
      beforeSend(event, hint) {
        // Some events have a null or empty message - check for the first exception
        const message = event.message || (event.exception?.values && event.exception.values[0]?.value)

        // Filter events that ignoreErrors misses
        if (message && filteredErrors.find((msg) => !!message.match(msg))) return null

        // Filter events triggered by an ignored script source
        const frameHasScript = (fr) => fr.filename && filteredScripts.find((scr) => fr.filename.match(scr))
        const exceptionIncludesScript = (exception) => {
          const frames = exception.stacktrace?.frames
          return frames?.length > 0 && frames.find(frameHasScript)
        }
        if (event.exception?.values && event.exception.values.find(exceptionIncludesScript)) return null

        // Add hint data to be seen on events in Sentry
        event.extra = hint
        return event
      },
    })

    Sentry.setContext(
      'user',
      pick(window.LZ.analyticsProps, ['current_user_id', 'current_customer', 'impersonating_user', 'impersonator_id']),
    )

    sentryConfig.initialized = true
  }
}

export function monitorApp(vueApp) {
  Sentry.attachErrorHandler(vueApp)
}
