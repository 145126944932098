import { fetchItemsApp } from 'clients/learnosity'
import { rerenderMathJax } from 'clients/mathjax'
import pager from './printer/pager'
import watchQuestionLoading from './printer/watch_question_loading'
import { captureException } from 'clients/sentry'

export async function onReadyPrint(ngieConfig) {
  if (ngieConfig) {
    const questionLoadingPromise = watchQuestionLoading()
    try {
      await fetchItemsApp(ngieConfig)
    } catch (e) {
      captureException(e)
    }
    await questionLoadingPromise
  }

  const mathEls = document.querySelectorAll('.math-tex')
  mathEls.forEach((mathEl) => {
    rerenderMathJax(mathEl)
  })

  window.MathJax.Hub.Queue(async () => {
    await pager()
    document.querySelector('.loading').remove()
    document.querySelector('#un-formatted').remove()
    document.querySelectorAll('.page-content').forEach((e) => e.classList.add('overflow-hidden'))
    window.setTimeout(window.print, 400)
  })
}
